import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    servicesScroller();
    ourProcessSlider();
    ourWorkSlider();
  },
};

$(window).on('resize', function () {
  $(
    '.home-our-services-section .scroller-wrp, .home-our-process-section .process-list-wrp, .home-our-work-section .work-wrp'
  ).flickity('resize');

  $(window).scroll(function () {
    ctaScrollEffect();
  });
});

$(window).on('load', function () {
  $(
    '.home-our-services-section .scroller-wrp, .home-our-process-section .process-list-wrp, .home-our-work-section .work-wrp'
  ).flickity('resize');
});

$(window).scroll(function () {
  bannerScrollEffect();
  ctaScrollEffect();
});

function bannerScrollEffect() {
  var scrolVal = $(window).scrollTop();

  if (scrolVal < 2000) {
    $('.home-banner-section .title-wrp').css(
      'transform',
      'translate(0,-' + scrolVal / 5 + '%)'
    );
    $('.home-banner-section .buttons-wrp').css(
      'transform',
      'translate(0,-' + scrolVal / 5 + '%)'
    );
    $('.home-banner-section').css('top', scrolVal / 1.5);
    $('.home-banner-section').css('background-position-y', scrolVal / 25);
  }
}

function ctaScrollEffect() {
  var scrolVal = $(window).scrollTop();

  var inViewPort = $('.home-cta-section').position().top;

  var scrollEffect = -500 + (scrolVal - inViewPort);

  // console.log('scrolVal:'+scrolVal);
  // console.log('inViewPort:'+inViewPort);
  // console.log('scrollEffect:' + scrollEffect);

  if (scrolVal > 4700) {
    $('.home-cta-section').css('background-position-y', scrollEffect / 25);
  }
}

function servicesScroller() {
  // Play with this value to change the speed
  let tickerSpeed = 1;

  let flickity = null;
  let isPaused = false;
  const slideshowEl = document.querySelector(
    '.home-our-services-section .scroller-wrp'
  );

  const update = () => {
    if (isPaused) return;
    if (flickity.slides) {
      flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
      flickity.selectedIndex = flickity.dragEndRestingSelect();
      flickity.updateSelectedSlide();
      flickity.settle(flickity.x);
    }
    window.requestAnimationFrame(update);
  };

  const pause = () => {
    isPaused = true;
  };

  const play = () => {
    if (isPaused) {
      isPaused = false;
      window.requestAnimationFrame(update);
    }
  };

  flickity = new Flickity(slideshowEl, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    selectedAttraction: 0.015,
    friction: 0.25,
  });

  flickity.x = 0;

  slideshowEl.addEventListener('mouseenter', pause, false);
  slideshowEl.addEventListener('focusin', pause, false);
  slideshowEl.addEventListener('mouseleave', play, false);
  slideshowEl.addEventListener('focusout', play, false);

  flickity.on('dragStart', () => {
    isPaused = true;
  });

  update();
}

function ourProcessSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.home-our-process-section .process-list-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.home-our-process-section .process-list-wrp .flickity-button').appendTo(
    $('.home-our-process-section .intro-content .flickity-button-wrp')
  );
}

function ourWorkSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.home-our-work-section .work-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });

  $('.home-our-work-section .work-wrp .flickity-button').appendTo(
    $('.home-our-work-section  .intro-content .flickity-button-wrp')
  );
}
