import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';
// import Scrollbar from 'smooth-scrollbar';

// Uses as the variable for "Initiate Load More" script
let loadMoreItems = 6;

// sticky header variable
var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // Scrollbar.initAll();

    // Initiate Load More
    $(document).ready(function () {
      $('.news-listing-item-wrp').slice(0, loadMoreItems).show();
      if ($('.news-listing-item-wrp:hidden').length != 0) {
        $('#loadMore').show();
      } else {
        $('#loadMore').hide();
      }
      $('#loadMore').on('click', function (e) {
        e.preventDefault();
        $('.news-listing-item-wrp:hidden').slice(0, loadMoreItems).slideDown();
        if ($('.news-listing-item-wrp:hidden').length == 0) {
          $('#loadMore').text('No More to view').fadeOut('slow');
        }
      });
    });

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function(){
      $('body, .mobile-burger-icon').toggleClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function(){
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">'+ $(this).find('> a').text() +'</div>');
      $(this).find('> a').click(function(e){
        e.preventDefault();
        console.log(this);
        $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
      });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function(){
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">'+ $(this).find('> a').text() +'</div>');
      $(this).find('> a').click(function(e){
        e.preventDefault();
        console.log(this);
        $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
      });
    });

    // return link click
    $('.return-link').each(function(){
      $(this).click(function(){
         $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());

    formScripts();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    delayHomeBannerVideo();
    buttonArrow();
    scrollOnID();
  },
};

$(window).on('resize', function () {
  $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper').flickity('resize');
});

$(window).on('load', function () {
  $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper').flickity('resize');
});

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
    $('.sticky-overlay').outerHeight($('.header').outerHeight());
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  $('.form-dropdown').select2({
    dropdownParent: $('.select2-wrapper'),
  });

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function gallerySectionSlider() {
  var cellAlignValue = ''

  if (matchMedia('screen and (max-width: 768px)').matches ) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
    cellAlign: cellAlignValue,
  });

  $('.gallery-section.v1 .flickity-button').appendTo($('.gallery-section.v1 .flickity-button-wrp'));
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);
  console.log($galleryImages);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
    viewed: function() {
      $galleryImages.viewer('zoomTo', 1);
    },
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){
      // console.log(typeof('#'+idName));
      // $('#'+idName).siblings().addClass(newIdName);
      $('#'+ newIdName).css('opacity','1');
      $('#'+ newIdName)[0].src += '&autoplay=1';
      $('#' + idName).css({
        'opacity' : '0',
        'z-index' : '-1',
      });
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var $videoSrc;
  $('.video-iframe-thumbnail').click(function() {
      $videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
  $('#video').attr('src',$videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0');
  })

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src',$videoSrc);
  })
}
// Popup Video Player for Two Column Section

// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').html())
  }, 3000);
}
// Delay Home Banner Video

function buttonArrow() {
  $('<div class="button-arrow"></div>').appendTo('.btn-primary, .button-wrapper-form, .cta-btn, .read-more-btn');

  $('.wpcf7-spinner').remove();

  $('.btn-circle').each(function(){
    $(this).wrapInner('<span></span>');
    $(this).find('span').clone().appendTo(this);
  });
}

function scrollOnID() {
  $('a').on('click', function() {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== '') {
      // Prevent default anchor click behavior
      // event.preventDefault();

      // Store hash
       var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top,
      }, 100, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
}